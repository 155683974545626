<template>
	<b-card no-body>
		<b-card-header>
			<b-card-title>HDEP % V/s HHEP %</b-card-title>
			<div>
				<div class="d-flex">
					<div class="d-flex align-items-center mr-1 font-weight-bold">Year</div>

					<v-select
						v-model="selected"
						label="year"
						:options="option"
						style="width: 120px"
						@input="getSelectedYear"
					></v-select>
				</div>
			</div>
		</b-card-header>

		<!-- chart -->
		<b-card-body>
			<vue-apex-charts type="line" height="350" :options="chartOptions" :series="series" :plugins="plugins" />
		</b-card-body>
	</b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle, BRow, BCol, BFormGroup } from "bootstrap-vue";
import vmson from "@/libs/empty";
import vSelect from "vue-select";
import { $themeColors } from "@themeConfig";
import VueApexCharts from "vue-apexcharts";
import axiosIns from "@/libs/axios";
export default {
	components: {
		BCard,
		BCardHeader,
		BCardBody,
		BCardSubTitle,
		BCardTitle,

		BRow,
		BCol,
		BFormGroup,
		vSelect,
		VueApexCharts,
	},
	data() {
		const currentYear = new Date().getFullYear();
		const pastYears = 10; // Adjust the number of past years you want to include
		const options = [];

		// Generating options for current year and past years
		for (let i = 0; i < pastYears; i++) {
			options.push(currentYear - i);
		}
		return {
			selected: currentYear, // Default selection set to current year
			option: options,
			min: "",
			max: "",
			plugins: [
				// to add spacing between legends and chart
				{
					beforeInit(chart) {
						/* eslint-disable func-names, no-param-reassign */
						chartOptions.legend.afterFit = function () {
							this.height += 20;
						};
						/* eslint-enable */
					},
				},
			],

			series: [
				{
					name: "HDEP",
					data: [],
				},
				{
					name: "HHEP",
					data: [],
				},
			],
			chartOptions: {
				chart: {
					height: 350,
					type: "line",
					dropShadow: {
						enabled: true,
						color: "#fff",
						top: 18,
						left: 7,
						blur: 10,
						opacity: 0.2,
					},
					toolbar: {
						show: false,
					},
				},
				colors: ["#d7d9f9", "#ff4961"],
				dataLabels: {
					enabled: true,
				},
				stroke: {
					curve: "smooth",
				},
				// title: {
				//   text: "Average High & Low Temperature",
				//   align: "left",
				// },
				grid: {
					borderColor: "#e7e7e7",

					row: {
						colors: ["#f3f3f3", "#f3f3f3"],
						opacity: 0.5,
					},
				},
				markers: {
					size: 1,
				},
				xaxis: {
					categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
					// title: {
					//   text: "Month",
					// },
				},
				yaxis: {
					// title: {
					//   text: "Temperature",
					// },
					min: 0,
					max: this.max,
				},
				legend: {
					position: "top",
					horizontalAlign: "left",
					align: "start",
					display: true,
					labels: {
						usePointStyle: true,
						padding: 15,
						boxWidth: 7,
					},
				},
			},
		};
	},
	created() {
		this.farmId = this.$route.params.farmId;
		this.getData();
	},
	methods: {
		getSelectedYear(event) {
			this.selected = event;
			this.getData();
		},
		getData(id) {
			let farmId = id;
			let url = "";
			let type = 3;
			// type is production type of breeder
			if (farmId === undefined) {
				farmId = this.$route.params.farmId;
			} else {
				farmId = id;
			}
			if (farmId) {
				url = `web/hdep-vs-hhep?year=${this.selected}&farmId=${farmId}&type=${type}`;
			} else {
				url = `web/hdep-vs-hhep?year=${this.selected}&type=${type}`;
			}
			this.$http.get(url).then((response) => {
				let hdep = [];
				let hhep = [];
				response.data.forEach((item) => {
					hdep.push(item.hdep);
					hhep.push(Math.floor(item.hhep));
				});
				let mapGraph = this.series.map((item) => {
					if (item.name === "HDEP") {
						item.data = hdep;
					} else {
						item.data = hhep;
					}
					return item;
				});
				this.min = Math.min(...mapGraph);
				this.max = Math.max(...mapGraph);
				this.series = [...mapGraph];
			});
		},
		getFarmById() {
			const farm_id = this.$route.params.farmId;
			axiosIns
				.get(`web/chick-placement-sale?year=${this.selected}`, {
					params: { farmId: id },
				})
				.then((response) => {
					this.info = response.data;
					this.carouselItems = response.data.sliderList;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
	},
	mounted() {
		vmson.$on("farmIdEvent", (id) => {
			this.getData(id);
		});
	},
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
