<template>
	<section id="dashboard-ecommerce">
		<b-row class="match-height">
			<!-- <b-col xl="4" md="6">
				<ecommerce-medal :data="data.name || data.user_name" />
			</b-col> -->
			<b-col>
				<ecommerce-statistics :data="data.statisticsItems" />
			</b-col>
		</b-row>
		<!-- 
		<b-row class="match-height">
			<b-col lg="4">
				<b-row class="match-height">
					<b-col lg="6" md="3" cols="6">
						<ecommerce-order-chart />
					</b-col>
					<b-col lg="6" md="3" cols="6">
						<ecommerce-profit-chart :data="data.statisticsProfit" />
					</b-col>
					<b-col lg="12" md="6">
						<ecommerce-earnings-chart :data="data.earningsChart" />
					</b-col>
				</b-row>
			</b-col>

			<b-col lg="8">
				<ecommerce-revenue-report :data="data.revenue" />
			</b-col>
		</b-row> -->

		<b-row class="match-height">
			<!-- Company Table Card -->
			<!-- <b-col lg="8">
				<ecommerce-company-table :table-data="data.companyTable" />
			</b-col> -->
			<!--/ Company Table Card -->

			<!-- Developer Meetup Card -->
			<!-- <b-col lg="4" md="6">
				<ecommerce-meetup :data="data.meetup" />
			</b-col> -->
			<!--/ Developer Meetup Card -->

			<!-- Browser States Card -->
			<!-- <b-col lg="4" md="6">
				<ecommerce-browser-states />
			</b-col> -->
			<!--/ Browser States Card -->

			<!-- Goal Overview Card -->
			<!-- <b-col lg="4" md="6">
				<ecommerce-goal-overview :data="data.goalOverview" />
			</b-col> -->
			<!--/ Goal Overview Card -->

			<!-- Transaction Card -->
			<!-- <b-col lg="4" md="6">
				<ecommerce-transactions :data="data.transactionData" />
			</b-col> -->
			<!--/ Transaction Card -->
		</b-row>
	</section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import EcommerceStatistics from "./EcommerceStatistics.vue";
import axiosIns from "@/libs/axios";

export default {
	components: {
		BRow,
		BCol,
		EcommerceStatistics,
	},
	data() {
		return {
			data: {},
			congratulations: {},
			slide: null,
			token: JSON.parse(localStorage.getItem("token")),
			refreshToken: JSON.parse(localStorage.getItem("RefreshToken")),
		};
	},
	created() {
		// data
		axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
		axiosIns.get("web/me").then((response) => {
			this.data = response.data;
			const userData = getUserData();
			this.congratulations = userData.name || userData.user_name;
		});
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
