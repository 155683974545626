<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-12 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">Dashboard</h2>
					</div>
				</div>
			</div>
		</div>

		<!-- Table Container Card -->
		<b-tabs class="m-2">
			<b-tab active>
				<template #title>
					<b-img :src="require('@/assets/images/svg/sources.svg')" style="width: 25px; margin-right: 5px" />
					<span>Broiler</span>
				</template>
				<BroilerDashboard />
			</b-tab>
			<b-tab lazy>
				<template #title>
					<b-img
						:src="require('@/assets/images/svg/feed-supplement.svg')"
						style="width: 22px; margin-right: 7px"
					/>
					<span>Breeder</span>
				</template>
				<BreederDashboard />
			</b-tab>
			<b-tab lazy>
				<template #title>
					<b-img :src="require('@/assets/images/svg/disease.svg')" style="width: 22px; margin-right: 7px" />
					<span>Layer</span>
				</template>
				<LayerDashboard />
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BTabs,
	BTab,
	BCardText,
	BFormDatepicker,
	BFormSelect,
	BImg,
} from "bootstrap-vue";

import vSelect from "vue-select";
import BroilerDashboard from "../dashboard/BroilerDashboard.vue";
import BreederDashboard from "../dashboard/BreederDashboard.vue";
import LayerDashboard from "../dashboard/LayerDashboard.vue";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BFormGroup,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		BCardText,
		BTabs,
		BTab,
		vSelect,
		BFormDatepicker,
		BFormSelect,
		BreederDashboard,
		LayerDashboard,
		BroilerDashboard,
		BImg,
		// DiseaseList1
	},
	data() {
		return {
			rows: null,
		};
	},
};
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
